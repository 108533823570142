<template>
    <div class="row">
        <div class="col-12 text-end">
            <a class="text-primary isLink" data-bs-toggle="collapse" href="#legend" role="button" aria-expanded="false" @click="showLegend = !showLegend" aria-controls="collapseExample">
                <fa icon="angle-up" v-if="showLegend" class="me-2"/>
                <fa icon="angle-down" v-else class="me-2"/>
                Legend
            </a>
        </div>

        <div class="collapse" id="legend">
            <!-- <div class="card card-body"> -->
                <div class="row border-top mx-0 pb-3 mt-3 pt-2">
                
                    <div class="col-12 mb-3 px-0">
                        <div class="lblTiny">Project Status</div>

                        <span class="badge bg-success me-3">WIP</span>
                        <span class="badge bg-purple me-3" >DONE</span>
                        <span class="badge bg-orange me-3" >CANCELED</span>
                    </div>
                    
                    <div class="col-12 px-0">
                        <div class="lblTiny">Document Status</div>

                        <div class="row">
                            <div class="col-6 col-lg-2">
                                <fa icon="file" size="lg" class="text-success ms-1 me-2" />DRAFT
                            </div>
                            <div class="col-6 col-lg-2">
                                <fa icon="envelope" size="lg" class="text-purple me-2" />INVITED
                            </div>
                            <div class="col-6 col-lg-2">
                                <fa icon="user-gear" size="lg" class="text-info me-1" />SIGNING
                            </div>
                            <div class="col-6 col-lg-2">
                                <fa icon="signature" size="lg" class="text-pink me-1" />ALLSIGNED
                            </div>
                            <div class="col-6 col-lg-2">
                                <fa icon="circle-xmark" size="lg" class="text-orange me-2" />REMOVED
                            </div>
                            <div class="col-6 col-lg-2">
                                <fa icon="database" size="lg" class="text-brown ms-1 me-2" />STORED
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </div> -->
        </div>

    </div>
    <!-- <div class="nextBox nextBox55 shadow-sm border">

        <div v-if="!showLegend" class="row">
            <div class="col-12 text-center">
                <span class="lblLegend">
                    <a href="#legend" data-bs-toggle="collapse">Legend <fa icon="angle-down" size="lg" class="ms-1" /></a>
                </span>
            </div>
        </div>

        <div class="collapse" id="legend" ref="legend">
            <div class="row">
                
                <div class="col-12 mb-3">
                    <div class="lblTiny">Project Status</div>

                    <span class="badge bg-success me-3">WIP</span>
                    <span class="badge bg-purple me-3" >DONE</span>
                    <span class="badge bg-orange me-3" >CANCELED</span>
                </div>
                
                <div class="col-12">
                    <div class="lblTiny">Document Status</div>

                    <div class="row">
                        <div class="col-6 col-lg-2">
                            <fa icon="file" size="lg" class="text-success ms-1 me-2" />DRAFT
                        </div>
                        <div class="col-6 col-lg-2">
                            <fa icon="envelope" size="lg" class="text-purple me-2" />INVITED
                        </div>
                        <div class="col-6 col-lg-2">
                            <fa icon="user-gear" size="lg" class="text-info me-1" />SIGNING
                        </div>
                        <div class="col-6 col-lg-2">
                            <fa icon="signature" size="lg" class="text-pink me-1" />ALLSIGNED
                        </div>
                        <div class="col-6 col-lg-2">
                            <fa icon="circle-xmark" size="lg" class="text-orange me-2" />REMOVED
                        </div>
                        <div class="col-6 col-lg-2">
                            <fa icon="database" size="lg" class="text-brown ms-1 me-2" />STORED
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 lblLegend text-center pt-4">
                    <a href="#legend" data-bs-toggle="collapse">
                        <Popper class="popperDark" arrow hover content="Close legend">
                            <fa icon="angle-up" size="lg" />
                        </Popper>  
                    </a>
                </div>
            </div>

        </div>
    </div> -->
</template>

<script>
import { ref, onMounted } from 'vue'
import Popper from 'vue3-popper'

export default {
    name: 'Legend',
    components: { Popper },
    setup() {

        const showLegend = ref(false)

        /* onMounted(() => {
            const obj = document.querySelector('#legend')
            // Save object changes when trigger select signee modal
            obj.addEventListener("hide.bs.collapse", function (event) {
                console.info('------ Trigger close')
                showLegend.value = false
            })

            obj.addEventListener("show.bs.collapse", function (event) {
                console.info('------ Trigger shown')
                showLegend.value = true
            })
        }) */

        return { showLegend }
    }
}
</script>

<style>
.legendContainer {
    width: 55%; 
    margin: 40px auto 0px auto;
    padding: 35px;
    border-radius: 10px;
}

.lblLegend a:hover {
    background-color: #EEEEEE;
    color: #6E777E;
    padding: 5px 8px;
    border-radius: 5px;
}
</style>