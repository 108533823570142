<template>
    <span v-if="tags && tags.length > 0" class="badge tag isLabel" v-for="(tag, index) in tags" :key="tag"><fa icon="hashtag" class="pe-1"/>{{ tag }}
        <!-- Small screen -->
        <span class="d-lg-none">
            <a href="#" class="ms-1" @click="delTag(tag, index)"><fa icon="xmark" /></a>
        </span>
        <!-- Big screen -->
        <span class="tagClose" @click="delTag(tag, index)"> x </span>
    </span>
</template>

<script>
import { ref, /* watch,  */onMounted } from 'vue'
export default {
    name: 'ProjTag',
    props: [ 'tags' ],
    emits: ['getTags', 'delTag'],
    setup (props, { emit }) {

        const tags = ref(props.tags)
        console.info('tags component', JSON.stringify(tags.value))

        /* watch([tags.value], () => {
            console.info('Tag:watch', tags.value)
            emit('getTags', tags.value)
        }) */

        const delTag= (val, index) => {
            emit("delTag", val, index)
        }

        onMounted(() => {
         })

        return { tags, delTag }
    }
}
</script>
// Without drop down menu
// Allow add multiple tags
// Not allow add duplicate tag
// Tag option based on project tag
<style>
.custom-dropdown {
    text-align: left;
    display: block;
    width: 100%;
    padding: 4px 5px;
    font-size: 1rem;
    font-weight: 400;
    height: auto;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 5px;
    margin-bottom: 5px;
}
.inputTag {
    width: 80px;
    height: 30px;
    font-size: 12px;
    border: 1px dashed #ced4da;
}

.inputTag:focus { 
    outline: none; 
    border: 1px dashed grey;
}
</style>