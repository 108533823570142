<template>
    <span v-if="value === 'PUBLIC'" class="badge bg-grey" :class="class">PUBLIC</span>
    <span v-if="value === 'PRIVATE'" class="badge bg-grey" :class="class">PRIVATE</span>
    <span v-if="value === 'CONFIDENTIAL'" class="badge bg-grey" :class="class">CONFIDENTIAL</span>
</template>

<script>
import { ref, onMounted } from 'vue'

export default {
    props: [ 'value', 'class' ],
    setup (props) {

        onMounted(() => {
            // console.info('Tag::onMounted', JSON.stringify(tags.value))
        })

        return {}
    }
}
</script>

<style>

</style>